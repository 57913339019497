import { Family, User } from '@/api/models';
import { LocalStorageStoreBase } from './local-storage-store-base';
// import stateStore from '@/store/state';

/**
 * The global store interface.
 */
export interface LoginStore extends Object {
    user?: User;
    selectedFamily?: Family;
}

class Store extends LocalStorageStoreBase<LoginStore> {
    constructor() {
        super('login');
    }

    protected data(): LoginStore {
        return {
            user: undefined
        };
    }

    /**
     * Sets a new login session to the application.
     * @param id The user id.
     * @param name The user name.
     * @param token The session token.
     */
    setLoginSession(id: string, name: string, token: string) {
        const newUser: User = { id, name, token };
        this.state.user = newUser;
    }

    setSelectedFamily(family?: Family) {
        this.state.selectedFamily = family;
    }

    /**
     * Logs out the user and removes the current session information (username, api token, wishlist etc).
     */
    logout() {
        this.state.user = undefined;
        this.state.selectedFamily = undefined;
    };
}

const loginStore: Store = new Store();
export default loginStore;
