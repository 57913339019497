import loginStore from "@/store/login";
import { ApiBase } from "./apiBase";
import { AuthenticationResult, Family, Friend, User, Wishlist } from './models';

class Api extends ApiBase {
    /**
     * Authenticates and login.
     * @param email The user's e-mail address to authenticate with.
     * @param password The user's password to authenticate with.
     */
    public authenticate(email: string, password: string): Promise<AuthenticationResult> {
        return this.post<AuthenticationResult>(`authenticate`, {
            email,
            password
        }, false);
    }

    /**
     * Returns all available users.
     */
    public getMyFamilies(): Promise<Family[]> {        
        return this.get<Family[]>(`get-families`);
    }

    public setPassword(userId: string, password: string): Promise<AuthenticationResult> {
        return this.put<AuthenticationResult>({ password }, `setpassword/${userId}`, false);
    }

    /**
     * Returns the friend and his/her wishlist.
     */
     public getFriend(): Promise<Friend | undefined> {
        const familyId = loginStore.getState().selectedFamily?.id;
        if (!familyId) { return Promise.reject("No family selected"); }

        return this.get<Friend | undefined>(`get-friend/${familyId}`);
    }

    /**
     * Returns the user's wishlist.
     */
     public getWishlist(): Promise<Wishlist> {
        const familyId = loginStore.getState().selectedFamily?.id;
        if (!familyId) { return Promise.reject("No family selected"); }

        return this.get<Wishlist>(`wishlist/${familyId}`);
    }

    /**
     * Updates the user's wishlist.
     */
     public setWishlist(wishlist: Wishlist): Promise<void> {
        const familyId = loginStore.getState().selectedFamily?.id;
        if (!familyId) { return Promise.reject("No family selected"); }

        return this.put<void>(wishlist, `wishlist/${familyId}`);
    }

    /**
     * Uploads the selected file to the wishlist blob storage.
     */
     public uploadFile(file: File): Promise<string[]> {
        return this.sendFiles<string[]>([file], `saveimage`);
    }
}

const api = new Api();
export default api;
