import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Main from '@/views/main.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Main',
        component: Main
    },
    {
        path: '/menu',
        name: 'Menu',
        component: () => import('@/views/menu.vue')
    },
    {
        path: '/edit-item/:id',
        name: 'EditItem',
        component: () => import('@/views/add-item.vue')
    },
    {
        path: '/add-item',
        name: 'AddItem',
        component: () => import('@/views/add-item.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
