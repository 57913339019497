import loginStore, { LoginStore } from '@/store/login';
import { HttpStatusCodes } from './http-status-codes';

export class ApiBase {
    protected async get<TEntity>(action?: string, data?: any, useToken: boolean = true): Promise<TEntity> {
        return this.sendHttpRequest<TEntity>('get', this.getAddress(), action, data, useToken ? this.getHeaders() : undefined);
    }

    protected async post<TEntity>(action?: string, data?: any, useToken: boolean = true): Promise<TEntity> {
        return this.sendHttpRequest<TEntity>('post', this.getAddress(), action, data, useToken ? this.getHeaders() : undefined);
    }

    protected async delete(action?: string, data?: any, useToken: boolean = true): Promise<void> {
        return this.sendHttpRequest<void>('delete', this.getAddress(), action, data, useToken ? this.getHeaders() : undefined);
    }

    /**
     * Sends a put request (update) to bridge with a entity as update body.
     * @param entity The entity to send as with the put request (the update).
     * @param action Any action to use when sending the put request.
     */
    protected async put<TEntity>(entity: object, action?: string, useToken: boolean = true): Promise<TEntity> {
        return this.sendHttpRequest('put', this.getAddress(), action, entity, useToken ? this.getHeaders() : undefined);
    }

    /**
     * Sends a HTTP request to the selected address.
     * @param method The method used for the HTTP request.
     * @param address The endpoint address to send the request to.
     * @param controller Any controller (part of the url) to send the request to.
     * @param action Any controller action (part of the url) to send the request to.
     * @param data Any additional data that will be sent as part of the body.
     * @param headers Any additional headers that will be included in the request.
     */
    protected async sendHttpRequest<TReturn>(
        method: "get" | "delete" | "head" | "options" | "post" | "put" | "patch" | "purge" | "link" | "unlink",
        address: string,
        action?: string,
        data?: any,
        headers?: any): Promise<TReturn> {
        
        // Create the url
        const actionUrl = action == null ? '' : `/${action}`;
        let error = undefined as undefined | { code: number, message: string };

        // Create and send the request
        const request = new Request(`${address}${actionUrl}`, {
            method,
            headers,
            body: data == null ? undefined : JSON.stringify(data)
        });

        const response = await fetch(request);

        // Get the response body
        const responseBody = await ApiBase.getResponseBody(response);

        // Something went wrong, throw an exception
        if (!response.ok) {
            console.error(response.statusText);

            // Handle if the error is due to not enough permissions or not logged in properly
            if (response.status === HttpStatusCodes.Unauthorized || response.status === HttpStatusCodes.Forbidden) {
                // The user is not properly logged in or has unsufficient permissionss,
                // reset any login information from state and force the user to try and login again
                loginStore.logout();
            }

            throw Error(`HTTP response ${response.status}: ${response.statusText}`);
        }

        // When successful, return the response body
        return responseBody;
    }

    /**
     * Sends a HTTP request to the selected address.
     */
    public async sendFiles<TReturn>(files: File[], action?: string, headers: any = this.getHeaders()): Promise<TReturn> {
        // Create the url
        const actionUrl = action == null ? '' : `/${action}`;
        const address = this.getAddress();

        // Create form data
        const formData = new FormData();
        for (let file of files) {
            formData.append('file', file);
        }

        // Set content-type correctly if not done already
        if (headers['Content-Type'] == null) {
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }
        // if (headers['Accept'] == null) {
        //     headers['Accept'] = 'image/png, image/jpeg';
        // }

        // Create and send the request
        const request = new Request(`${address}${actionUrl}`, {
            method: 'post',
            headers,
            body: formData
        });

        const response = await fetch(request);

        // Get the response body
        const responseBody = await ApiBase.getResponseBody(response);

        // Something went wrong, throw an exception
        if (!response.ok) {
            console.error(response.statusText);

            // Handle if the error is due to not enough permissions or not logged in properly
            if (response.status === HttpStatusCodes.Unauthorized || response.status === HttpStatusCodes.Forbidden) {
                // The user is not properly logged in or has unsufficient permissionss,
                // reset any login information from state and force the user to try and login again
                loginStore.logout();
            }

            throw Error(`HTTP response ${response.status}: ${response.statusText}`);
        }

        // When successful, return the response body
        return responseBody;
    }

    private static async getResponseBody(response: Response): Promise<any> {
        if (response.status === HttpStatusCodes.NoContent) { return undefined; }

        // Parse the response body as an object
        let text = null;
        try {
            text = await response.text();
            if (text === null || text === '') { return undefined; }

            const body = JSON.parse(text);
            return body;
        } catch (err) {
            // Do nothing, just return nothing
            if (text != null && text != '') {
                throw new Error(text);
            } else {
                throw new Error('Could not decode or parse the server response properly.');
            }
        }
    }

    /**
     * Returns the address to the server.
     */
    protected getAddress() {
        // return 'https://5p8kzdws-7071.euw.devtunnels.ms/api'; // code server
        // return 'http://localhost:7071/api'; // TEST
        return 'https://julefrid.azurewebsites.net/api';
    }

    /**
     * Gets the current session token.
     */
    protected getToken() {
        const token = loginStore.getState().user?.token;

        if (token == null) {
            throw new Error('No token available.');
        }

        return token;
    }

    protected getHeaders() {
        return {
            'Authorization': `Bearer ${this.getToken()}`
        };
    }
}