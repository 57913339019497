/**
 * Includes some of the most common http status codes.
 * Add additional codes if needed.
 */
export enum HttpStatusCodes {
    /** 200: Successful request. */
    OK = 200,
    /** 201: The request has been fulfilled, resulting in the creation of a new resource. */
    Created = 201,
    /** 202: The request has been accepted for processing, but the processing has not been completed. The request might or might not be eventually acted upon, and may be disallowed when processing occurs. */
    Accepted = 202,
    /** 204: The server successfully processed the request, and is not returning any content. */
    NoContent = 204,

    /** 400: The server cannot or will not process the request due to an apparent client error. */
    BadRequest = 400,
    /** 401: Authentication is required and has failed or is not yet provided. */
    Unauthorized = 401,
    /** 403: The request contained valid data and was understood by the server, but the server is refusing action. */
    Forbidden = 403,
    /** 404: The requested resource could not be found but may be available in the future. Subsequent requests by the client are permissible. */
    NotFound = 404,

    /** 500: A generic error message, given when an unexpected condition was encountered and no more specific message is suitable. */
    InternalServerError = 500,
    /** 502: The server was acting as a gateway or proxy and received an invalid response from the upstream server. */
    BadGateway = 502,
}
